import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.css';

export class Footer extends Component {
    static displayName = Footer.name;

    render() {
        return (
            <>
                <Navbar fixed="bottom" className="border-top bg-body-tertiary text-muted renu360-footer">
                    <Container>
                        <Row className="w-100">
                            <Col className="col-6">&copy; 2023 - CHA Integrated Solutions, LLC.</Col>
                            <Col className="col-6 text-end">20241004.2</Col>
                        </Row>
                    </Container>
                </Navbar>
            </>
        );
    }
}
